import { render } from 'datocms-structured-text-to-html-string';
import Head from 'next/head';
import type { PageContentFragment_FaqDropdownRecord_items } from '~/types/generated/cms/PageContentFragment';
import type { StructuredText } from 'datocms-structured-text-utils';
import type { RenderSettings } from 'datocms-structured-text-to-html-string';

interface GoogleStructuredDataItem {
  '@type': 'Question';
  name: string;
  acceptedAnswer: {
    '@type': 'Answer';
    text: string;
  };
}

// Create type that can be re used and is based off the FaqItemFragment
type FaqItems = readonly PageContentFragment_FaqDropdownRecord_items[];

interface Props {
  faqItems: FaqItems;
}

const renderOptions: RenderSettings<any> = {
  renderInlineRecord: () => null,
  renderLinkToRecord: () => null,
  renderBlock: () => null,
};

const convertFaqItemsToStructuredData = (faqItems: FaqItems): GoogleStructuredDataItem[] => {
  return faqItems.map(i => {
    return {
      '@type': 'Question',
      name: i.question!,
      acceptedAnswer: {
        '@type': 'Answer',
        text: render(i.answer as unknown as StructuredText, renderOptions)!,
      },
    };
  });
};

const FaqGoogleStructuredData: React.FC<Props> = ({ faqItems }) => {
  const stringifiedStructuredData = JSON.stringify(convertFaqItemsToStructuredData(faqItems));

  return (
    <Head>
      <script
        id="googleScript"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": ${stringifiedStructuredData}
          }
          `,
        }}
      ></script>
    </Head>
  );
};

export default FaqGoogleStructuredData;
